<template>
  <div class="grid grid-cols-2 gap-1">
    <div class="grid grid-rows-2 justify-items-center">
      <h6>Battery Level</h6>
      <div class="grid grid-cols-3 justify-items-center gap-2">
        <div class="inline-flex">
          <img
            class="mt-[-3px] w-[32px]"
            :src="icons['battery-normal']"
          >
          Normal
        </div>
        <div class="inline-flex">
          <img
            class="mt-[-3px] w-[32px]"
            :src="icons['battery-serious']"
          >
          Less than 10%
        </div>
        <div class="inline-flex">
          <img
            class="mt-[-3px] w-[32px]"
            :src="icons['battery-critical']"
          >
          Less than 5%
        </div>
      </div>
    </div>
    <div class="grid grid-rows-2 justify-items-center">
      <h6>Data Reception</h6>
      <div class="grid grid-cols-4 justify-items-center gap-2">
        <div class="inline-flex">
          <img
            class="mt-[-3px] w-[32px]"
            :src="icons['wifi-normal']"
          >
          Normal
        </div>
        <div class="inline-flex">
          <img
            class="mt-[-3px] w-[32px]"
            :src="icons['wifi-warning']"
          >
          Not within 48 Hours
        </div>
        <div class="inline-flex">
          <img
            class="mt-[-3px] w-[32px]"
            :src="icons['wifi-serious']"
          >
          Not within 72 Hours
        </div>
        <div class="inline-flex">
          <img
            class="mt-[-3px] w-[32px]"
            :src="icons['wifi-critical']"
          >
          Not within 5 Days
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 justify-items-center">
      <div class="inline-flex">
        <img
          class="mt-[-3px] w-[32px]"
          :src="icons['disable-gray']"
        >
        Inactivated Customer
      </div>
    </div>

    <div class="grid grid-cols-1 justify-items-center">
      <div class="inline-flex">
        <img
          class="mt-[-3px] w-[32px]"
          :src="icons['reverse-diagnostic']"
        >
        Month reverse flow is greater than 5 m³
      </div>
    </div>
  </div>
</template>

<script>
import appIcons from '@leecom/app-icons'

export default {
  data() {
    return {
      icons: appIcons,
    }
  },
}
</script>

<style></style>
