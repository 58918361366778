const appIcons = {
    'battery-normal': require('@/assets/images/icons/app/battery-normal.svg'),
    'battery-diagnostic': require('@/assets/images/icons/app/battery-diagnostic.svg'),
    'battery-warning': require('@/assets/images/icons/app/battery-warning.svg'),
    'battery-serious': require('@/assets/images/icons/app/battery-serious.svg'),
    'battery-critical': require('@/assets/images/icons/app/battery-critical.svg'),
    'battery-gray': require('@/assets/images/icons/app/battery-gray.svg'),
    'battery-black': require('@/assets/images/icons/app/battery-black.svg'),
    'battery-default': require('@/assets/images/icons/app/battery-default.svg'),


    'wifi-normal': require('@/assets/images/icons/app/wifi-normal.svg'),
    'wifi-diagnostic': require('@/assets/images/icons/app/wifi-diagnostic.svg'),
    'wifi-warning': require('@/assets/images/icons/app/wifi-warning.svg'),
    'wifi-serious': require('@/assets/images/icons/app/wifi-serious.svg'),
    'wifi-critical': require('@/assets/images/icons/app/wifi-critical.svg'),
    'wifi-gray': require('@/assets/images/icons/app/wifi-gray.svg'),
    'wifi-black': require('@/assets/images/icons/app/wifi-black.svg'),
    'wifi-default': require('@/assets/images/icons/app/wifi-default.svg'),

    'reverse-normal': require('@/assets/images/icons/app/reverse-normal.svg'),
    'reverse-diagnostic': require('@/assets/images/icons/app/reverse-diagnostic.svg'),
    'reverse-warning': require('@/assets/images/icons/app/reverse-warning.svg'),
    'reverse-serious': require('@/assets/images/icons/app/reverse-serious.svg'),
    'reverse-critical': require('@/assets/images/icons/app/reverse-critical.svg'),
    'reverse-gray': require('@/assets/images/icons/app/reverse-gray.svg'),
    'reverse-black': require('@/assets/images/icons/app/reverse-black.svg'),
    'reverse-default': require('@/assets/images/icons/app/reverse-default.svg'),


    'disable-normal': require('@/assets/images/icons/app/disable-normal.svg'),
    'disable-diagnostic': require('@/assets/images/icons/app/disable-diagnostic.svg'),
    'disable-warning': require('@/assets/images/icons/app/disable-warning.svg'),
    'disable-serious': require('@/assets/images/icons/app/disable-serious.svg'),
    'disable-critical': require('@/assets/images/icons/app/disable-critical.svg'),
    'disable-gray': require('@/assets/images/icons/app/disable-gray.svg'),
    'disable-black': require('@/assets/images/icons/app/disable-black.svg'),
    'disable-default': require('@/assets/images/icons/app/disable-default.svg'),
};

export default appIcons;