<template>
  <div>
    <b-card no-body class="mb-0 pt-[0.2rem]">
      <b-table
        ref="refDataListTable"
        class="position-relative table-data tv-screen-table"
        :items="fetchDataList"
        responsive
        hover
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :tbody-tr-class="resolveRowClass"
        :sticky-header="true"
      >
        <!-- Virtual Column: Index -->
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <!-- Column: Address -->
        <template #cell(address)="data">
          {{ resolveAddressLabel(data.item) }}
        </template>

        <!-- Column: Meter Size -->
        <template #cell(meter_size)="data">
          {{ resolveMeterSizeLabel(data.item.meter_size) }}
        </template>

        <!-- Column: Meter Model -->
        <template #cell(meter_model)="data">
          {{ resolveMeterModelLabel(data.item.meter_model) }}
        </template>

        <!-- Column: Aquifers -->
        <template #cell(aquifers)="data">
          {{ resolveAquifersLabel(data.item.aquifers) }}
        </template>

        <!-- Column: Consumption -->
        <template #cell(total_plus)="data">
          <div class="text-right">
            {{ data.item.total_plus }}
          </div>
        </template>

        <!-- Column: Reverse Flow -->
        <template #cell(total_minus)="data">
          <div class="text-right">
            {{ data.item.total_minus }}
          </div>
        </template>

        <!-- Column: Last Communication -->
        <template #cell(last_communication_at)="data">
          <div class="inline-flex justify-end w-full">
            <div class="text-right self-center">
              {{ data.item.last_communication_at }}
            </div>
          </div>
        </template>

        <!-- Column: Main Battery -->
        <template #cell(main_battery)="data">
          <div class="inline-flex justify-end w-full">
            <div class="text-right self-center">
              {{ data.item.main_battery }}
            </div>
            <img
              v-if="data.item.main_battery_state"
              class="ml-[0.2rem] w-[32px]"
              :title="data.item.main_battery_state_title"
              :src="icons['battery-' + data.item.main_battery_state]"
            />
            <img
              v-else
              class="ml-[0.2rem] w-[32px]"
              title="N/A"
              :src="icons['battery-default']"
            />
          </div>
        </template>

        <!-- Column: 3G/4G Battery -->
        <template #cell(gsm_battery)="data">
          <div class="inline-flex justify-end w-full">
            <div class="text-right self-center">
              {{ data.item.gsm_battery }}
            </div>
            <img
              v-if="data.item.gsm_battery_state"
              class="ml-[0.2rem] w-[32px]"
              :title="data.item.gsm_battery_state_title"
              :src="icons['battery-' + data.item.gsm_battery_state]"
            />
            <img
              v-else
              class="ml-[0.2rem w-[32px]"
              title="N/A"
              :src="icons['battery-default']"
            />
          </div>
        </template>

        <!-- Column: Health Status -->
        <template #cell(health_status)="data">
          <div class="inline-flex justify-end w-full">
            <span>
              <img
                v-if="data.item.customer_state_color"
                class="ml-[0.4rem] w-[32px]"
                :title="data.item.customer_state_title"
                :src="icons['disable-' + data.item.customer_state]"
              />
            </span>
            <span>
              <img
                v-if="data.item.reverse_flow_state_color"
                class="ml-[0.4rem] w-[32px]"
                :title="data.item.reverse_flow_state_title"
                :src="icons['reverse-' + data.item.reverse_flow_state]"
              />
            </span>
            <span>
              <img
                v-if="data.item.last_communication_at"
                class="ml-[0.4rem] w-[32px]"
                :title="data.item.communication_state_title"
                :src="icons['wifi-' + data.item.communication_state]"
              />
              <img
                v-else
                class="ml-[0.4rem]"
                title="N/A"
                :src="icons['wifi-default']"
              />
            </span>
          </div>
        </template>

        <!-- Column: GPS -->
        <template #cell(latitude)="data">
          <div class="text-right inline-flex">
            {{ data.item.latitude }}, {{ data.item.longitude }}
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" class="flex flex-col mb-1 mb-md-0">
            <!-- <div class="grid grid-cols-1 justify-items-center">
              <div>
                <h6>Commination Status</h6>
              </div>
              <div class="grid grid-cols-4">
                <div>
                  <img
                    class="ml-[0.4rem] w-[32px]"
                    :src="icons['wifi-normal']"
                  >
                  <h6>Normal</h6>
                </div>
                <div>
                  <img
                    class="ml-[0.4rem] w-[32px]"
                    :src="icons['wifi-warning']"
                  >
                  <h6>Warning</h6>
                </div>
                <div>
                  <img
                    class="ml-[0.4rem] w-[32px]"
                    :src="icons['wifi-serious']"
                  >
                  <h6>Serious</h6>
                </div>
                <div>
                  <img
                    class="ml-[0.4rem] w-[32px]"
                    :src="icons['wifi-critical']"
                  >
                  <h6>Critical</h6>
                </div>
              </div>
            </div> -->

            <!-- <div class="grid grid-cols-1 justify-items-center">
              <div>
                <h6>Battery Status</h6>
              </div>
              <div class="grid grid-cols-3">
                <div>
                  <img
                    class="ml-[0.4rem] w-[32px]"
                    :src="icons['battery-normal']"
                  >
                  <h6>Normal</h6>
                </div>
                <div>
                  <img
                    class="ml-[0.4rem] w-[32px]"
                    :src="icons['battery-serious']"
                  >
                  <h6>Serious</h6>
                </div>
                <div>
                  <img
                    class="ml-[0.4rem] w-[32px]"
                    :src="icons['battery-critical']"
                  >
                  <h6>Critical</h6>
                </div>
              </div>
            </div> -->
            <legend-block />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSidebar,
  BTr,
  BTh,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import appIcons from '@leecom/app-icons'
import LegendBlock from './LegendBlock.vue'

export default {
  components: {
    BCard,
    BRow,
    BTr,
    BTh,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSidebar,
    vSelect,
    LegendBlock,
  },
  props: {
    module: {
      type: Object,
      required: true,
    },
    useList: {
      type: Function,
      required: true,
    },
    moduleName: {
      type: String,
      required: true,
    },
  },
  setup(props, { root: $this, emit }) {
    const STORE_MODULE_NAME = props.moduleName
    if (!store.hasModule(STORE_MODULE_NAME))
      store.registerModule(STORE_MODULE_NAME, props.module)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME))
        store.unregisterModule(STORE_MODULE_NAME)

      clearInterval(interval1)
      clearInterval(interval2)
    })

    const icons = ref(appIcons)

    const {
      fetchDataList,
      tableColumns,
      perPage,
      currentPage,
      totalRows,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      filterInputs,

      refetchData,

      resolveAddressLabel,
      resolveMeterSizeLabel,
      resolveMeterModelLabel,
      resolveAquifersLabel,
      resolveRowClass,
      resolveHealthStatusTdClass,

      basicFilter,

      interval1,
      interval2,
    } = props.useList()

    return {
      fetchDataList,
      tableColumns,
      perPage,
      currentPage,
      totalRows,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      filterInputs,

      refetchData,

      resolveAddressLabel,
      resolveMeterSizeLabel,
      resolveMeterModelLabel,
      resolveAquifersLabel,
      resolveRowClass,
      resolveHealthStatusTdClass,

      basicFilter,

      icons,
    }
  },
}
</script>
