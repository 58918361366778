<template>
  <div class="absolute z-[1000] right-[60px] top-[90px]">
    <h1 class="text-[2.5rem]">
      {{ time }}
    </h1>
  </div>
</template>

<script>
import moment from 'moment-timezone'

moment.locale('en')

export default {
  data() {
    return {
      time: '',
    }
  },
  created() {
    setInterval(() => {
      this.time = moment.tz('Asia/Bahrain').format('HH:mm:ss')
    })
  },
}
</script>

<style></style>
