<template>
  <div>
    <clock />
    <tv-screen-table
      :module="TVScreenStoreModule"
      :use-list="useTVScreenList"
      module-name="app-tv-screen"
    />
  </div>
</template>

<script>
import TVScreenTable from './TVScreenTable'
import useTVScreenList from './useTVScreenList'
import TVScreenStoreModule from './TVScreenStoreModule'
import TVScreenClock from './TVScreenClock.vue'

export default {
  components: {
    'tv-screen-table': TVScreenTable,
    clock: TVScreenClock,
  },
  data() {
    return {
      useTVScreenList,
      TVScreenStoreModule,
    }
  },
}
</script>

<style></style>
